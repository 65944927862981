import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import URI from 'urijs'
import {
  useDineInCart,
  useOrderMethod,
  useStores,
} from 'react-omnitech-api'
import DineInView from './dine-in-view'
import { useLink, useThemeConfig } from '../../hook'

function getQueryFromUrl(href) {
  const url = new URI(href)
  const query = url.search(true)
  return query
}

function DineInController(props) {
  const {
    location,
  } = props
  const { t } = useTranslation()
  const { navigate } = useLink()
  const {
    availableOrderMethod,
  } = useThemeConfig()
  const {
    clearOrderMethod,
    setOrderMethod,
    setStore,
    orderMethod,
  } = useOrderMethod()
  const { cart: dineInCart, createCart, resetStagingCart } = useDineInCart()
  const { fetchStore } = useStores()
  const [showCompleteMessage, setShowCompleteMessage] = useState(false)
  const [createCartInProgress, setCreateCartInProgress] = useState(false)
  const [createCartSuccess, setCreateCartSuccess] = useState(false)
  const dineInOrderMethod = _.find(availableOrderMethod, { code: 'dineIn' })

  const origOrderMethod = useMemo(() => orderMethod, [])
  const url = _.get(location, 'href')

  const qrCodeData = useMemo(() => {
    const query = getQueryFromUrl(url)
    return query
  }, [url])

  useEffect(() => {
    if (_.isEmpty(qrCodeData)) {
      clearOrderMethod()
      resetStagingCart()
      setShowCompleteMessage(true)
    }
  }, [qrCodeData])
  useEffect(() => {
    if (_.isEmpty(qrCodeData)) return
    setOrderMethod(dineInOrderMethod)
    setCreateCartInProgress(true)
  }, [createCart, dineInOrderMethod, qrCodeData, setOrderMethod])

  useEffect(() => {
    if (
      !createCartInProgress
      || createCartSuccess
      || _.get(orderMethod, 'code') !== 'dineIn'
      || _.isEmpty(qrCodeData)
    ) return
    // check if current cart have items or not
    // if ()
    handleCreateDineInCart()
  }, [createCartInProgress, qrCodeData, _.get(orderMethod, 'code')])

  const handleCreateDineInCart = async () => {
    const option = {
      params: {
        includes: [
          'cart_shipments',
          'cart_shipments.cart_lines',
          'cart_shipments.inventory_store',
          'cart_lines.sku_id',
          'carts.consumer',
          'carts.meta',
          'carts.internal_app',
          'internal_apps.hardware',
          'hardwares.store',
          'users.loyalty_information',
        ],
      },
      payload: {
        schemaVersion: '2021-06-08',
        data: {
          cart: {
            // [TODO] use qrcode data after api is ready
            nfcData: qrCodeData,
          },
        },
      },
    }
    try {
      const includes = [
        'meta',
        'today_open_time_slots',
      ]
      const data = await createCart(option)
      const storeId = _.get(data, 'cart.internalApp.hardware.store.id')
      const { store } = await fetchStore({ id: storeId, includes })
      setStore(store)
      setCreateCartSuccess(true)
      // Navigate to PLP/Menu
      navigate('/products/')
    } catch (error) {
      console.log('///// catch error .....', error)
      // set orderMethod back to original
      setOrderMethod(origOrderMethod)
      // show error message
      setShowCompleteMessage(true)
    } finally {
      setCreateCartInProgress(false)
    }
  }

  // [TODO]
  // - get qr code data from url
  // - create cart with qr code data
  // - update order method
  // - navigate to products page

  // local variable
  const seoTitle = t('screens.dineIn.seo.title')
  const viewProps = {
    createCartInProgress,
    seoTitle,
    showCompleteMessage,
  }

  return (
    <DineInView {...viewProps} />
  )
}

export default DineInController
