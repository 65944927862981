import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  SEO,
  MessageScreen,
  Layout,
  Loading,
} from '../../ui'
import useStyles from './dine-in-style'

function DineInView({
  createCartInProgress,
  seoTitle,
  showCompleteMessage,
}) {
  const { t } = useTranslation()
  const styles = useStyles()
  return (
    <Layout>
      <SEO title={seoTitle} />
      <div className={styles.containerFluid}>
        {
          createCartInProgress && <Loading />
        }
        {
          showCompleteMessage && (
            <MessageScreen
              title={t('screens.dineIn.complete.title')}
              message={t('screens.dineIn.complete.message')}
              button={{
                text: t('screens.dineIn.complete.button'),
                navigate: '/products/',
              }}
            />
          )
        }
      </div>
    </Layout>
  )
}

export default DineInView
